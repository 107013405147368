import React from 'react'
import Particles from 'react-particles-js'

import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { snowParticles } from 'src/components/hero/particlesConfig'

import 'src/components/hero/hero.scss'

const Hero = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          benAndNat: file(relativePath: { eq: "ben-natalie-hero.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div className="Hero">
            <Particles params={snowParticles} className="Hero__canvas" />
            <Img
              fluid={data.benAndNat.childImageSharp.fluid}
              alt="Ben and Natalie Weiser"
            />
            <div className="Hero__content">
              <div className="Hero__content-inner">
                <div className="Hero__pretext">The wedding ceremony of</div>
                <div className="Hero__text">Natalie & Ben Weiser</div>
                <div className="Hero__subtext">02.16.19</div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export default Hero
