import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import Title from 'src/components/title/title'
import Card from 'src/components/card/card'

import styles from './things-to-do.module.scss'

const ThingsToDo = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          fremontSt: file(relativePath: { eq: "fremont-east.jpg" }) {
            childImageSharp {
              fixed(width: 300, height: 160, cropFocus: NORTH) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          redRock: file(relativePath: { eq: "red-rock-canyon.jpg" }) {
            childImageSharp {
              fixed(width: 300, height: 160, cropFocus: NORTHEAST) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          springsPreserve: file(relativePath: { eq: "springs-preserve.jpg" }) {
            childImageSharp {
              fixed(width: 300, height: 160, cropFocus: SOUTHEAST) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div>
            <Title withLine>Things To Do Around Town</Title>
            <p>
              We're honored to have you join us for our wedding, but don't stay
              idle in your free time. Enjoy the bride and groom's
              recommendations for fun things to do while you're in town for our
              celebration. Lots of great recommendations{' '}
              <a href="https://localadventurer.com/ultimate-las-vegas-bucket-list/">
                can be found here
              </a>
              .
            </p>
            <div className={styles.grid}>
              <Card
                title="Fremont East District"
                image={data.fremontSt.childImageSharp.fixed}
                description="North of the Strip, next to the famous lightshow canopy is an eclectic non-gaming bar district with lots of fun to be had! Check out Banger Brewing for a local microbrew."
                link="http://fremonteast.com/"
              />
              <Card
                title="Red Rock Canyon"
                image={data.redRock.childImageSharp.fixed}
                description="Enjoy beautiful hikes in one of the most picturesque scenes in the West. Try out the Pine Creek trail where you can see the ruins of an old homestead next to a stream. Leave early it gets busy!"
                link="https://www.redrockcanyonlv.org/"
                imageRight
              />
              <Card
                title="Springs Preserve"
                image={data.springsPreserve.childImageSharp.fixed}
                description="One of Ben's favorite places ever. The Nevada State Museum is here along with an outdoor desert garden, walking trails and a butterfly habitat. Great place for kids!"
                link="https://www.springspreserve.org/"
              />
            </div>
          </div>
        )
      }}
    />
  )
}

export default ThingsToDo
