import React from 'react'

import Title from 'src/components/title/title'
import ImageGallery from 'src/components/image-gallery/image-gallery'

const OurStory = () => (
  <div>
    <Title withLine>Our Story</Title>
    <div style={{ maxWidth: '700px', margin: '0 auto', padding: '0 16px' }}>
      <p>
        One evening in early November 2016 in Las Vegas, Natalie was swiping
        away on the Bumble dating app with the hope of finding a non-LA guy. She
        came to Vegas to spend time with her sister and their family and decided
        to take a shot and see what was out there.{' '}
      </p>
      <p>
        Lo and behold, Ben’s profile appeared, and it was everything Natalie had
        ever hoped for and more. She found the man she had always dreamt of and
        sent him her opening line “What’s your scotch of choice?”. They hit it
        off instantly over text and talked for a few days until Ben decided it
        was time to finally meet. By that time, Natalie had already gone back to
        LA, so they kept in touch for almost a month. Ben decided a month was
        way too long and he drove to LA for just one full day to take Natalie on
        a date to Catcher in the Rye (per Natalie’s recommendation). It was an
        instant spark when they locked eyes at the corner on the bar (the same
        place Ben proposed 1 year later) and have been together ever since.
      </p>
      <p>
        {' '}
        They bond over their love of travel, exploring new places, hiking,
        trying new and exciting restaurants, drinking scotch and craft beer, and
        the importance of family and friends. We are very excited to share our
        special day with our closest family and friends. We thank you for coming
        near and far to take part in our celebration. We invite you to look over
        the website Ben built (being the true developer he is) to find tips,
        suggestions, and general information regarding our wedding day. Thank
        you for visiting our page and can’t wait to see you on February 16th,
        2019!
      </p>
    </div>
    <ImageGallery />
  </div>
)

export default OurStory
