import React from 'react'
import Lightbox from 'react-images'

import { graphql, StaticQuery } from 'gatsby'

import styles from 'src/components/image-gallery/image-gallery.module.scss'

const serializeImagesForGallery = images =>
  Object.values(images).reduce(
    (formattedImages, currentImage) =>
      formattedImages.concat({
        src: currentImage.childImageSharp.fixed.src,
        srcSet: currentImage.childImageSharp.fixed.srcSet,
      }),
    []
  )

export default props => (
  <StaticQuery
    query={graphql`
      query {
        jamesonBday: file(relativePath: { eq: "jameson-bday.JPG" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        dinner: file(relativePath: { eq: "dinner.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        goldenKnights: file(relativePath: { eq: "golden-knights.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        hiking: file(relativePath: { eq: "hiking.jpg" }) {
          childImageSharp {
            fixed(width: 700, cropFocus: SOUTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        hollywoodHills: file(relativePath: { eq: "hollywood-hills.JPG" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        justUs: file(relativePath: { eq: "just-us.jpg" }) {
          childImageSharp {
            fixed(width: 700, cropFocus: SOUTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        lima: file(relativePath: { eq: "lima.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        macchu1: file(relativePath: { eq: "macchu.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        macchu3: file(relativePath: { eq: "macchu-3.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        peru: file(relativePath: { eq: "peru-1.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        peruDinner: file(relativePath: { eq: "peru-dinner.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        peruHiking: file(relativePath: { eq: "peru-hiking.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        ponchos: file(relativePath: { eq: "ponchos.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        queen: file(relativePath: { eq: "queen.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        ragnar: file(relativePath: { eq: "ragnar.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        valleyOfFire: file(relativePath: { eq: "valley-of-fire.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        walkingVenice: file(relativePath: { eq: "walking-venice.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        zion: file(relativePath: { eq: "zion.JPG" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        blackMountain: file(relativePath: { eq: "black-mountain.JPG" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        narrows: file(relativePath: { eq: "narrows.JPG" }) {
          childImageSharp {
            fixed(width: 700, cropFocus: SOUTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        highRoller: file(relativePath: { eq: "highroller.JPG" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        japaneseGarden: file(relativePath: { eq: "japanese-garden.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        sledding: file(relativePath: { eq: "sledding.JPG" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        stratosphere: file(relativePath: { eq: "stratosphere.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        engagement1: file(relativePath: { eq: "engagement-1.jpg" }) {
          childImageSharp {
            fixed(width: 700, cropFocus: SOUTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        engagement2: file(relativePath: { eq: "engagement-2.jpg" }) {
          childImageSharp {
            fixed(width: 700, cropFocus: SOUTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        engagement3: file(relativePath: { eq: "engagement-3.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        engagement4: file(relativePath: { eq: "engagement-4.jpg" }) {
          childImageSharp {
            fixed(width: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => <ImageGallery images={serializeImagesForGallery(data)} />}
  />
)

class ImageGallery extends React.Component {
  state = {
    currentImage: 0,
    isLightBoxOpen: false,
  }

  handleOpen = (index, event) => {
    console.log('index', index)
    console.log('event', event)
    event.preventDefault()
    this.setState({
      currentImage: index,
      isLightBoxOpen: true,
    })
  }

  handleClose = () => {
    this.setState({
      currentImage: 0,
      isLightBoxOpen: false,
    })
  }

  handlePrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }

  handleNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }

  handleGoToImage = index => {
    this.setState({
      currentImage: index,
    })
  }

  renderGallery = () => {
    const { images } = this.props
    if (!images) {
      return null
    }

    return (
      <div className={styles.galleryThumbs}>
        {images.map((image, index) => {
          return (
            <a
              href="#"
              key={image.src}
              onClick={event => this.handleOpen(index, event)}
            >
              <img src={image.src} alt="gallery image" />
            </a>
          )
        })}
      </div>
    )
  }

  render() {
    const { currentImage, isLightBoxOpen } = this.state
    const { images } = this.props
    return (
      <div className={styles.gallery}>
        <h3 style={{ textAlign: 'center' }}>Some Of Our Favorite Memories</h3>
        {this.renderGallery()}
        <Lightbox
          currentImage={currentImage}
          isOpen={isLightBoxOpen}
          onClose={this.handleClose}
          onClickThumbnail={this.handleGoToImage}
          onClickNext={this.handleNext}
          onClickPrev={this.handlePrevious}
          preventScroll={true}
          images={images}
          showThumbnails
        />
      </div>
    )
  }
}
