import React from 'react'
import classNames from 'classnames'

import Logo from 'src/components/logo/logo'
import Button from 'src/components/button/button'

import style from './gatekeeping-modal.module.scss'

class GatekeepingModal extends React.Component {
  static pw = 'mario'
  state = {
    error: null,
    isModalOpen: true,
    value: '',
  }

  handleFocus = () => {
    if (this.state.error) {
      this.setState({
        error: null,
        value: '',
      })
    }
  }

  handleChange = e => {
    this.setState({ value: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.value.toLowerCase().trim() === GatekeepingModal.pw) {
      if (this.state.error) {
        this.setState({
          error: null,
        })
      }
      this.props.onEntryAllow(e)
      localStorage.setItem('canEnterWeddingSite', true)
    } else {
      this.setState({
        error: `Sorry this password isn't correct. If you're a wedding guest please double check your invitation or email Ben bweiser86@gmail.com for help accessing the website`,
      })
    }
  }

  render() {
    const { error, isModalOpen } = this.state

    if (!this.state.isModalOpen) {
      return null
    }
    return (
      <div>
        <div className={style.modal}>
          <h1>The Wedding Of Natalie Steiner & Ben Weiser</h1>
          <Logo className={style.logo} />
          <p>
            Please enter the <strong>father of the bride's first name</strong> in all
            lowercase to enter our website. (Look on your wedding invitation).
          </p>
          <form onSubmit={this.handleSubmit}>
            <input
              className={classNames({
                [style.error]: error,
              })}
              type="text"
              value={this.state.value}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
            />
            <Button type="submit" variant="primary">
              Enter Website
            </Button>
          </form>
          {error && <p className={style.error}>{error}</p>}
        </div>
      </div>
    )
  }
}

export default GatekeepingModal
