import React from 'react'
import Img from 'gatsby-image'
import classNames from 'classnames'

import styles from './image-tile.module.scss'

const ImageTile = ({ src, alt, children }) => (
  <div
    className={classNames({
      [styles.wrapper]: true,
      //      [styles.red]: true,
      //  [styles.dark_red]: true,
      //  [styles.blue]: true,
    })}
  >
    <Img className={styles.image} fixed={src} alt={alt} />
    <div className={styles.text}>
      <div className={styles.innerText}>{children}</div>
      <div>&mdash;</div>
    </div>
  </div>
)

export default ImageTile
