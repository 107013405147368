import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'

import ImageTile from 'src/components/image-tile/image-tile'
import styles from './gift-registry.module.scss'
import Title from '../title/title'

const GiftRegistry = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          honeyMoon: file(relativePath: { eq: "gift-honeymoon.jpeg" }) {
            childImageSharp {
              fixed(width: 300, height: 300) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          amazon: file(relativePath: { eq: "registry-amazon.jpg" }) {
            childImageSharp {
              fixed(width: 300, height: 300, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          bedBathAndBeyond: file(relativePath: { eq: "registry-bed.jpg" }) {
            childImageSharp {
              fixed(width: 300, height: 300, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          williamsSonoma: file(
            relativePath: { eq: "registry-williams-sonoma.jpg" }
          ) {
            childImageSharp {
              fixed(width: 300, height: 300, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div
            className={classNames({
              [styles.wrapper]: true,
              [styles.jagged]: true,
            })}
          >
            <Title noTopMargin>Where We're Registered</Title>
            <div className={styles.grid}>
              <a
                className={styles.item}
                href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=4JX4DSWYBZ3AG"
                target="_blank"
              >
                <ImageTile
                  src={data.honeyMoon.childImageSharp.fixed}
                  alt="donate to our honey moon"
                >
                  Honeymoon Fund
                </ImageTile>
              </a>

              <a
                className={styles.item}
                href="https://www.williams-sonoma.com/registry/pspxzjfq8k/registry-list.html?cm_type=lnav"
                target="_blank"
              >
                <ImageTile
                  src={data.williamsSonoma.childImageSharp.fixed}
                  alt="Williams Sonoma"
                >
                  Williams Sonoma
                </ImageTile>
              </a>
              <a
                className={styles.item}
                href="https://www.bedbathandbeyond.com/store/giftregistry/viewregistryguest/545809484"
                target="_blank"
              >
                <ImageTile
                  src={data.bedBathAndBeyond.childImageSharp.fixed}
                  alt="bed bath beyond"
                >
                  Bed Bath and Beyond
                </ImageTile>
              </a>
              <a
                className={styles.item}
                href="https://www.amazon.com/wedding/benjamin-weiser-natalie-steiner-las-vegas-february-2019/registry/2OM60XHDPNPRH"
                target="_blank"
              >
                <ImageTile src={data.amazon.childImageSharp.fixed} alt="amazon">
                  Amazon
                </ImageTile>
              </a>
            </div>
          </div>
        )
      }}
    />
  )
}

export default GiftRegistry
