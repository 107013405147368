import React from 'react'

import className from 'classnames'
import Img from 'gatsby-image'

import styles from './card.module.scss'

const Card = ({ alt, image, title, link, description, imageRight }) => (
  <div className={styles.card}>
    {image && (
      <Img
        className={className({
          [styles.image]: true,
          [styles.imageRight]: imageRight,
        })}
        fixed={image}
        alt={alt}
      />
    )}
    <div className={styles.content}>
      {title && <div className={styles.title}>{title}</div>}
      {description && <div className={styles.description}>{description}</div>}
      {link && (
        <a className={styles.link} href={link}>
          Visit Website
        </a>
      )}
    </div>
  </div>
)

export default Card
