import React from 'react'

import className from 'classnames'

import styles from './title.module.scss'

const Title = ({ children, withLine, noTopMargin }) => (
  <h2
    className={className({
      [styles.title]: true,
      [styles.with_line]: withLine,
      [styles.no_top_margin]: noTopMargin,
    })}
  >
    {children}
  </h2>
)

export default Title
