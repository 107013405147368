import React from 'react'

import 'src/components/button/button.scss'

const Button = ({
  children,
  variant,
  hasShadow,
  isInverted,
  ...remainingProps
}) => {
  let classString = `Button`
  if (variant) {
    classString += ` Button--${variant}`
    if (isInverted) {
      classString += '--inverse'
    }
  }

  if (hasShadow) {
    classString += ' Button--shadow'
  }

  return (
    <button className={classString} {...remainingProps}>
      {children}
    </button>
  )
}

export default Button
