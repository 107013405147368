import React from 'react'
import '../styles'

import SiteWrap from 'src/components/wrap/wrap'
import Layout from 'src/components/layout'
import Hero from 'src/components/hero/hero'
import EventDetails from 'src/components/event-details/event-details'
import GiftRegistry from 'src/components/gift-registry/gift-registry'
import OurStory from 'src/components/our-story/our-story'
import EventMap from 'src/components/event-map/event-map'
import GatekeepingModal from 'src/components/gatekeeping-modal/gatekeeping-modal'
import ThingsTodo from 'src/components/things-to-do/things-to-do'
import Footer from 'src/components/footer/footer'

class IndexPage extends React.Component {
  state = {
    isAllowedEntry: false,
  }

  onEntryAllow = e => {
    e.preventDefault()

    this.setState({
      isAllowedEntry: true,
    })
  }

  renderSiteOrModal = () => {
    if (this.state.isAllowedEntry) {
      return (
        <Layout>
          <section id="top">
            <Hero />
          </section>
          <SiteWrap>
            <section id="our-story">
              <OurStory />
            </section>
          </SiteWrap>
          <section id="gift-registry" style={{ marginBottom: '48px' }}>
            <GiftRegistry />
          </section>
          <SiteWrap>
            <section id="event-details" style={{ marginBottom: '64px' }}>
              <EventDetails />
            </section>
          </SiteWrap>
          <section id="getting-there" style={{ marginBottom: '48px' }}>
            <EventMap />
          </section>
          <section id="things-to-do" style={{ marginBottom: '48px' }}>
            <SiteWrap>
              <ThingsTodo />
            </SiteWrap>
          </section>
          <Footer />
        </Layout>
      )
    }
    return (
      <div>
        <GatekeepingModal onEntryAllow={this.onEntryAllow} />
      </div>
    )
  }

  componentDidMount() {
    if (localStorage.getItem('canEnterWeddingSite')) {
      this.setState({
        isAllowedEntry: true,
      })
    }
  }

  render() {
    return this.renderSiteOrModal()
  }
}

export default IndexPage
