import React from 'react'

import styles from './footer.module.scss'

const Footer = () => (
  <div className={styles.footer}>
    Website designed and developed by Ben Weiser (The Groom). &copy;{' '}
    {new Date().getFullYear()}
  </div>
)

export default Footer
