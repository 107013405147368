import React from 'react'
import AddToCalendar from 'react-add-to-calendar'

import Frame from 'src/components/frame/frame'
import Title from 'src/components/title/title'

import styles from './event-details.module.scss'

const EventDetails = () => (
  <div className={styles.event_details}>
    <Title withLine>Event Details</Title>
    <Frame>
      <div>
        <h3 className={styles.heading}>When</h3>
        <div className={styles.text}>Saturday, February 16th</div>
      </div>
      <div className={styles.text}>5:30 pm</div>
      <div>
        <h3 className={styles.heading}>Where</h3>
        <div className={styles.text}>JW Marriott</div>
        <div className={styles.text}>221 N. Rampart Boulevard</div>
      </div>
      <div className={styles.text}>Las Vegas, NV</div>
      <div>
        <h3 className={styles.heading}>Accommodations</h3>
        <div className={styles.text}>
          Reserve a room in our wedding block at the JW Marriott.{' '}
          <a
            href="https://book.passkey.com/event/49644914/owner/25334/landing"
            target="_blank"
          >
            Book Now
          </a>
        </div>
      </div>
      <h3 className={styles.heading}>Reception to follow</h3>
      <AddToCalendar
        useFontAwesomeIcons
        buttonTemplate={{ 'calendar-plus-o': 'left' }}
        displayItemIcons
        rootClass={styles.calendar}
        buttonWrapperClass={styles.calendar_button}
        dropdownClass={styles.calendar_dropdown}
        event={{
          title: `Natalie Steiner & Ben Weiser's Wedding`,
          location: 'Las Vegas, NV',
          startTime: '2016-09-16T20:15:00-04:00',
          endTime: '2016-09-16T21:45:00-04:00',
        }}
      />
    </Frame>
  </div>
)

export default EventDetails
